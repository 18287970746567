import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  public plus: number[];

  constructor() { }

  ngOnInit() {
    this.plus = this.createRange(156);
  }

  title = "¿Qué es?";
  description = "Nuestro proyecto busca traquear esas emociones basándonos en el modelo de ";  
  description1 = "Robert Plutchik´s";
  description2 = ", que trabaja con las 8 emociones centrales y cómo su combinación lleva a nuevas emociones.";
  link = "https://en.wikipedia.org/wiki/Robert_Plutchik";
  image = {
    name: "image 1",
    url: "assets/images/wheel_desktop.svg",
    urlMobile: "assets/images/wheel_mobile.svg"
  };

  createRange(number: any) {
    var items: number[] = [];
    for (var i = 1; i <= number; i++) {
      items.push(i);
    }
    return items;
  } 

}
