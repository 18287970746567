import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  
  menu: any[] =  [
    {
      title: "Dashboard",
      url: "#home-section"
    },
    {
      title: "¿Qué es?",
      url: "#about-section"
    },
    {
      title: "¿Por qué?",
      url: "#reason-section"
    },
    {
      title: "¿Cómo ayuda a las marcas?",
      url: "#brands-section"
    },
    {
      title: "¿Cómo usar?",
      url: "#use-section"
    },
    {
      title: "Contacto",
      url: "#contact-section"
    },
  ]
}
