import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public plus: number[];

  constructor() { }

  ngOnInit() {
    this.plus = this.createRange(156);
  }

  title = "Reframing Economy";
  description = "Un trabajo de Wunderman Thompson que busca entender cómo las emociones de los colombianos cambian sus comportamientos y afectan la intensión de consumo.";
  link = "https://datastudio.google.com/embed/u/0/reporting/d7e78404-0f5d-4da0-8c22-650121697de0/page/moAMB";
  
  createRange(number: any) {
    var items: number[] = [];
    for (var i = 1; i <= number; i++) {
      items.push(i);
    }
    return items;
  } 

}
