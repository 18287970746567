import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss']
})
export class BrandsComponent implements OnInit {

  public plus: number[];

  constructor() { }

  ngOnInit() {
    this.plus = this.createRange(143);
  }

  title = "¿Cómo esto ayuda a las marcas?";
  description = "Esta visión nos ayuda a transformar el journey:";  

  elements = [
    {
      title: "Reconocer",
      description: "Reconocer nuevos desencadenantes.",
      image: "assets/images/reconocer.svg"
    },
    {
      title: "Repensar",
      description: "Repensar puntos de contacto.",
      image: "assets/images/repensar.svg"
    },
    {
      title: "Reconsiderar",
      description: "Reconsiderar puntos de dolor.",
      image: "assets/images/reconsiderar.svg"
    },
    {
      title: "Reequilibrar",
      description: "Reequilibrar la emoción.",
      image: "assets/images/reequilibrar.svg"
    },
    {
      title: "Reimaginar",
      description: "Reimaginar escenarios.",
      image: "assets/images/reimaginar.svg"
    }
  ];

  createRange(number: any) {
    var items: number[] = [];
    for (var i = 1; i <= number; i++) {
      items.push(i);
    }
    return items;
  }
}
