import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-use',
  templateUrl: './use.component.html',
  styleUrls: ['./use.component.scss']
})
export class UseComponent implements OnInit {

  public plus: number[];

  constructor() { }

  ngOnInit() {
    this.plus = this.createRange(108);
  }
  
  createRange(number: any) {
    var items: number[] = [];
    for (var i = 1; i <= number; i++) {
      items.push(i);
    }
    return items;
  }

  title = "¿Cómo usar el dashboard?";
  description = "Ya estas listo para ingresar y visualizar toda la información presentada.";  
  link = "https://datastudio.google.com/embed/u/0/reporting/d7e78404-0f5d-4da0-8c22-650121697de0/page/moAMB";

  elements = [
    {
      description: "Usa los diferentes selectores y filtros para ver diferentes resultados y análisis.",
      image: "assets/images/selectores.png"
    },
    {
      description: "Navega entre las distintas pestañas para encontrar lo que estás buscando.",
      image: "assets/images/hallazgos.png"
    },
    {
      description: "También puedes escuchar los análisis con los principales insights, haciendo clic en el botón de audio.",
      image: "assets/images/escuchar.png"
    }
  ];
}
