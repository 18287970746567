import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  title = "Contacto";
  description = "Si tienes alguna duda o inquietud por favor no dudes en contactarnos al siguiente correo:";
  email = "data.manager@wundermanthompson.com";

}
