import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reason',
  templateUrl: './reason.component.html',
  styleUrls: ['./reason.component.scss']
})
export class ReasonComponent implements OnInit {

  public plus: number[];

  constructor() { }

  ngOnInit() {
    this.plus = this.createRange(156);
  }

  title = "¿Por Qué?";
  description = "El significado del valor está cambiando los productos y servicios, básicamente porque ese valor está siendo dominado por emociones modificando las prioridades y valoraciones de los colombianos.";
  image = {
    name: "image 1",
    url: "assets/images/colors.png"
  };
  
  createRange(number: any) {
    var items: number[] = [];
    for (var i = 1; i <= number; i++) {
      items.push(i);
    }
    return items;
  } 
}